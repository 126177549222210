<template>
	<div class="container">
		<stockTopMenu windowId="deptStock" module="stock">
			<div slot="descript" class="row" style="margin-left: 300px">
				<div
					class="row align-center cursor tab-item"
					style="margin-left: 100px; color: #067cf2; border-bottom: 4px solid #067cf2; margin-top: 20px"
				>
					<p style="padding-bottom: 15px">
						<img
							src="@/assets/images/tabIcon/start-select.png"
							style="width: 18px; height: 18px; margin-right: 10px; float: left; margin-top: 2px"
						/>
						<span>开始盘点</span>
					</p>
				</div>
				<div class="row align-center cursor tab-item" style="margin-left: 100px" @click="routeChange">
					<p>
						<img
							src="@/assets/images/tabIcon/record.png"
							style="width: 18px; height: 18px; margin-right: 10px; float: left; margin-top: 2px"
						/>
						<span>盘点记录</span>
					</p>
				</div>
			</div>
		</stockTopMenu>
		<div class="cont-tab-top">
			<div class="cont-tab-top-l">
				<div class="lock-area">
					<img v-if="notInput" @dblclick="unlockdb" src="@/assets/images/lock.png"/>
					<img v-else @dblclick="unlockdb" src="@/assets/images/unlock.png"/>
				</div>
				<div class="scan-type">
					<span :class="[scanType == 1 ? 'cur-scan' : '']">正扫</span>
					<img v-show="scanType == 1" @click="scanHande" src="@/assets/images/zhengscan.png"/>
					<img v-show="scanType == 2" @click="scanHande" src="@/assets/images/fanscan.png"/>
					<span :class="[scanType == 2 ? 'cur-scan' : '']">负扫</span>
				</div>
				<div class="change-num">
					<div class="circle" @click="numberChange('add')">
						<i class="el-icon-minus circle-icon"></i>
					</div>
					<span class="circle-num">{{ scanNumber }}</span>
					<div class="circle" @click="numberChange('cut')">
						<i class="el-icon-plus circle-icon"></i>
					</div>
				</div>
				<div class="scan-card">
					<div class="scan-title">{{ areaData.areaName }}</div>
					<input
						class="scan-input"
						type="text"
						ref="tagInput"
						v-model="scanCode"
						:disabled="isDisable"
						placeholder="请扫码或输入条码"
						v-focusInput="1"
						@keyup.enter="pushBarCode"
					/>
					<div class="scan-btn" @click="pushBarCode()">确 定</div>
				</div>
				<el-button size="small" type="primary" plain @click="openGoodsNoDialog">选择货品添加</el-button>
				<el-button size="small" icon="el-icon-delete" type="danger" @click="clearAll">清 空</el-button>
			</div>
			<div class="cont-tab-top-r">
				<div class="submit-content">明细总件数：{{ totalUnit }}件</div>
				<div class="submit-btn" @click="submitDialog">提交明细</div>
			</div>
		</div>
		<section class="table-data">
			<el-row
				:gutter="20"
				style="margin-left: 0; margin-right: 0; padding: 10px 0; background: #fff; height: 100%"
			>
				<el-col :span="6">
					<div class="col-top">
						<span class="col-title">条码盘点</span>
						<div class="search-box">
							<span class="search-label">条码查询</span>
							<el-input
								class="search-input"
								size="small"
								v-model="searchCode"
								placeholder="请输入条码,多个用空格隔开"
								@change="searchCodeFn"
								@keyup.enter.native="searchCodeFn()"
								clearable
							>
							</el-input>
							<el-button size="small" type="primary" @click="searchCodeFn">查 询</el-button>
						</div>
					</div>
					<el-table
						class="bar-code-table"
						header-cell-class-name="bar-code-table-header-cell"
						:data="barCodeFilterList"
						:row-class-name="setRowClassName"
						height="700px"
						border
					>
						<el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
						<el-table-column prop="barCode" label="条码" align="left"></el-table-column>
						<el-table-column prop="scanNumber" label="盘点数量" width="100" align="center">
						</el-table-column>
						<el-table-column label="操作" width="80" align="center">
							<template v-slot="{ row }">
								<div class="row-btn el-icon-delete" @click="rowDel(row)"></div>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
				<el-col :span="18">
					<div class="col-top col-top-r">
						<span class="col-title">货号汇总</span>
						<div class="search-box">
							<span class="search-label">货号</span>
							<erp-selector
								class="search-input-goodsNo"
								type="goodsNo"
								v-model="goodsNoList"
								modelKey="goodsNo"
								:slectShow="false"
								:inputShow="true"
								placeholder="请输入货号，多个用空格隔开"
								@change="searchList"
							></erp-selector>
							<el-button size="small" type="primary" @click="searchList">查 询</el-button>
						</div>
					</div>
					<SizeGroupTable
						ref="sizeGroupTableIns"
						height="700px"
						:dataList="dataList"
						:sizeInfoLabels="sizeInfoLabel"
						:filterText="filterText"
						:columns="columns"
						:isEmpty="true"
						border
					>
					</SizeGroupTable>
				</el-col>
			</el-row>
		</section>
		<dialogModule
			width="600px"
			v-if="dialogVisible"
			:title="title"
			:dialogVisible="dialogVisible"
			@close="dialogVisible = false"
		>
            <span
				slot="close"
				class="el-icon-close"
				style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer"
				@click="dialogVisible = false"
			></span>
			<div
				slot="content"
				style="padding: 10px 20px; min-height: 300px"
				v-loading="loading"
				element-loading-text="正在加载中，请稍后..."
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.3)"
			>
				<section v-if="dialogType == 1" style="font-size: 16px; font-family: 'font-Regular'">
					<div class="stock-row" style="margin-top: 10px">
						盘点总件数：<span>{{ totalUnit }}件</span>
					</div>
					<div class="stock-row" style="margin-top: 20px; margin-bottom: 10px">备注：</div>
					<el-input type="textarea" placeholder="请输入内容" v-model="detailNote"></el-input>
				</section>
				<section class="choose-add" v-if="dialogType == 4" style="margin-top: 30px">
					<div class="row goods-search" style="margin-top: -30px; margin-bottom: 15px">
						<el-input
							v-model="condition"
							placeholder="条码/货号/货品名称/简码"
							@keyup.enter.native="queryGoods()"
						></el-input>
						<span
							@click="queryGoods()"
							style="
                                background: #067cf2;
                                color: #fff;
                                width: 140px;
                                border-radius: 0 4px 4px 0;
                                line-height: 36px;
                                text-align: center;
                                cursor: pointer;
                            "
						>搜 索</span
						>
					</div>
					<div class="row align-center flex-wrap space-between">
						<div
							class="goods row"
							style="
                                border: 1px solid #eee;
                                border-radius: 10px;
                                width: 49%;
                                margin-bottom: 10px;
                                height: 80px;
                                position: relative;
                            "
							v-for="item in goodsList"
							:key="item.goodsId"
						>
							<div class="goods-left">
								<el-image lazy :src="item.image ? item.image[0] : ''" :preview-src-list="item.image"
										  style=" width: 70px;
                                    height: 78px;
                                    float: left;
                                    margin-right: 10px;
                                    border-radius: 10px 0 0 10px;">
									<div slot="error" class="row align-center space-center"
										 style="background:#f5f5f5;height:100%;font-size:30px;color:#ccc;">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
							<div class="goods-right">
								<div>{{ item.goodsName }}</div>
								<div style="color: #999;">货号：{{ item.goodsNo }}</div>
								<div style="color: #999;">简码：{{ item.goodsCode }}</div>
								<div style="color: #f8475f;">￥{{ item.unitPrice }}</div>
							</div>
							<div
								style="
                                    position: absolute;
                                    width: 40px;
                                    height: 27px;
                                    line-height: 27px;
                                    border-radius: 50%;
                                    text-align: center;
                                    background: red;
                                    right: 10px;
                                    bottom: 10px;
                                    cursor: pointer;
                                    color: #fff;
                                "
								@click="queryCurgoods(item.goodsNo)"
							>添加
							</div>
						</div>
					</div>
					<el-pagination
						layout="total, prev, pager, next, jumper"
						:pager-count="5"
						:page-size="10"
						:total="totalItem"
						@current-change="queryGoods"
					>
					</el-pagination>
				</section>
				<div class="row align-cneter space-end" v-if="dialogType != 4" style="margin-top: 90px">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button v-if="dialogType == 1" type="primary" @click="submitDetail" :loading="loading"
					>提交明细
					</el-button
					>
					<el-button v-else type="primary" :loading="loading">确 定</el-button>
				</div>
			</div>
		</dialogModule>
		<dialogModule
			width="600px"
			title="选择规格"
			v-if="goodsDetail && dialogVisibleTwo"
			:dialogVisible="dialogVisibleTwo"
			@close="dialogVisibleTwo = false"
		>
            <span
				slot="close"
				class="el-icon-close"
				style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer"
				@click="dialogVisibleTwo = false"
			></span>
			<div slot="content" style="padding: 10px 20px">
				<div class="row align-center">
					<div v-if="colorSize">
						<el-image :src="colorSize.image ? colorSize.image[0] : ''" :preview-src-list="colorSize.image"
								  style="width: 100px; height: 100px; float: left; margin-right: 10px; border-radius: 4px">
							<div slot="error" class="row align-center space-center"
								 style="background:#f5f5f5;height:100%;font-size:30px;color:#ccc;">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
					</div>
					<div class="column" style="height: 92px">
						<div style="font-weight: bold">{{ goodsDetail.goodsName }}</div>
						<div style="color: #f8475f; margin-top: 10px">￥{{ goodsDetail.unitPrice }}</div>
					</div>
				</div>
				<div v-if="goodsDetail.colorInfoList">
					<div style="margin: 10px 0; color: #000; font-weight: bold">颜色</div>
					<div class="row">
						<div
							v-for="(item, c) in goodsDetail.colorInfoList"
							:key="c"
							@click="
                                curColorDesc = item.colorDesc
                                colorSize = item
                            "
							:class="['default-style', curColorDesc == item.colorDesc ? 'select-style' : '']"
						>
							{{ item.colorDesc }}
						</div>
					</div>
				</div>
				<div v-if="colorSize && colorSize.sizeInfoList">
					<div style="margin: 10px 0; color: #000; font-weight: bold">尺码</div>
					<div class="row flex-wrap">
						<div
							v-for="(item, s) in colorSize.sizeInfoList"
							:key="s"
							@click="
                                curSizeDesc = item.size
                                barCode = item.barCode
                            "
							:class="['default-style', curSizeDesc == item.size ? 'select-style' : '']"
						>
							{{ item.size }}
						</div>
					</div>
				</div>
				<div
					class="row align-center space-between"
					style="
                        background: #f9f9f9;
                        border-radius: 4px;
                        padding: 5px 20px;
                        box-sizing: border-box;
                        height: 40px;
                        margin: 20px 0;
                    "
				>
					<div style="color: #000">
						已选择：
						<span style="margin-right: 20px; color: #666">颜色：{{ curColorDesc }}</span>
						<span style="color: #666">尺码：{{ curSizeDesc }}码</span>
					</div>
					<div class="row align-center cursor">
						<div
							style="
                                width: 40px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                background: #f3f3f3;
                                color: #666;
                                border-radius: 4px 0 0 4px;
                                font-size: 16px;
                            "
							@click="
                                () => {
                                    if (this.dialogNumber == 1) return
                                    this.dialogNumber--
                                }
                            "
						>
							<i class="el-icon-minus"></i>
						</div>
						<div style="background: #fff;">
							<input
								style="width: 60px; height: 30px; line-height: 30px; text-align: center;border: none;outline: none;"
								type="text" v-model="dialogNumber" @change="changNumber">
						</div>
						<div
							style="
                                width: 40px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                background: #067cf2;
                                color: #fff;
                                border-radius: 0 4px 4px 0;
                                font-size: 16px;
                            "
							@click="dialogNumber++"
						>
							<i class="el-icon-plus"></i>
						</div>
					</div>
				</div>
				<div class="row align-center space-center" style="margin-top: 20px">
					<el-button @click="dialogVisibleTwo = false">取 消</el-button>
					<el-button type="primary" @click="sureAdd" :loading="loading">确 定</el-button>
				</div>
			</div>
		</dialogModule>
		<createPrve @sendAreaData="getAreaData"></createPrve>
	</div>
</template>

<script>
import stockTopMenu from '@/components/stockTopMenu.vue'
import dialogModule from '@/components/dialogModule.vue'
import createPrve from './components/createPrve.vue'
import erpSelector from '@/components/ErpSelector/index'
import {queryGoodsInfo, queryCurgoodsInfo, addRecordInfo, queryGoodsByBarcode} from '@/libs/http/modules/stock'
import {columns, sizeInfoLabel} from './config.js'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import _ from 'lodash'

export default {
	components: {
		stockTopMenu,
		dialogModule,
		createPrve,
		erpSelector,
		SizeGroupTable
	},
	data() {
		return {
			columns,
			sizeInfoLabel,
			notInput: true,
			goodsNoList: '',
			filterText: '',
			delKey: 0,
			barCodeList: [],
			barCodeFilterList: [],
			dataList: [],
			scanNumber: 1,
			scanType: 1,
			areaData: {},
			scanCode: '',
			totalUnit: 0,
			searchCode: '',
			loading: false,
			barCode: '',
			dialogNumber: 1,
			dialogVisible: false,
			title: '',
			dialogType: 1,
			detailNote: '',
			goodsList: [],
			condition: '',
			totalItem: 0,
			dialogVisibleTwo: false,
			goodsDetail: null,
			colorSize: null,
			curColorId: '',
			curColorDesc: '',
			curSizeId: '',
			curSizeDesc: '',
			curRowNumber: '',
			submitList: [],
			isDisable: false
		}
	},
	computed: {
		scanTip: () => new Audio(require('@/assets/audio/scan_tip.mp3')),
		scanWaring: () => new Audio(require("@/assets/audio/warning.mp3"))
	},
	methods: {
		setRowClassName(record) {
			return record.rowIndex === 0 ? 'select' : ''
		},
		submitDialog() {
			if (this.notInput) {
				return this.$message({
					type: 'error',
					message: '请双击最左边的“锁”解锁',
					duration: 2000,
					showClose: true
				})
			}
			if (!this.dataList.length) {
				return this.$message({type: 'error', message: '请先添加明细再提交', duration: 2000, showClose: true})
			}
			this.dialogVisible = true
			this.title = '盘点明细单信息'
			this.dialogType = 1
		},
		submitDetail() {
			let sendList = []
			this.dataList.forEach((row) => {
				row.sizeInfoList.forEach((item) => {
					if (item.scanNumber) {
						sendList.push({
							barCode: item.barCode,
							amount: item.scanNumber,
							areaId: this.areaData.areaId
						})
					}
				})
			})
			let subTotal = sendList.reduce((c, R) => c + Number(R.amount || 0), 0)
			if(Number(subTotal) !== Number(this.totalUnit)){
				this.$confirm(`本次盘点因输入法原因导致盘点数量有误，请检查后再提交`, '提示', {
					showClose: false,
					showCancelButton: false
				}).then(res=>{
					this.dialogVisible = false
				}).catch(()=>{})
			}else {
				this.loading = true
				addRecordInfo({
					type: '0',
					items: sendList,
					remark: this.detailNote,
					id: this.areaData['stockOrdingId'],
					detailId: this.areaData['recordDetailId']
				}).then((data) => {
					this.dialogVisible = false
					this.barCodeList = []
					this.barCodeFilterList = []
					this.dataList = []
					this.routeChange()
					this.loading = false
					this.$message({type: 'success', message: '提交成功', duration: 2000, showClose: true})
				}).catch((err) => {
					this.loading = false
					this.$message({type: 'error', message: err, duration: 2000, showClose: true})
				})
			}
		},
		routeChange() {
			if (this.dataList.length) {
				this.$confirm('当前正在盘点切换后本次盘点数据将会清空，是否确认', '提示').then(() => {
					this.$router.push('/stockRecord')
				}).catch(() => {})
			} else {
				this.$router.push('/stockRecord')
			}
		},
		getAreaData(e) {
			this.areaData = e
			this.$nextTick(() => {
				this.$refs.tagInput.focus()
			})
		},
		unlockdb() {
			this.notInput = !this.notInput
			if (!this.notInput) {
				this.$nextTick(() => {
					this.$refs.tagInput.focus()
				})
			}
		},
		
		//正负扫切换
		scanHande() {
			if (this.notInput) {
				return this.$message({
					type: 'error',
					message: '请双击最左边的“锁”解锁',
					duration: 2000,
					showClose: true
				})
			}
			if (this.scanType == 1) {
				this.scanType = 2
				this.scanNumber = -1
			} else if (this.scanType == 2) {
				this.scanType = 1
				this.scanNumber = 1
			}
		},
		//扫码数量变化
		numberChange(type) {
			if (this.notInput) {
				return this.$message({
					type: 'error',
					message: '请双击最左边的“锁”解锁',
					duration: 2000,
					showClose: true
				})
			}
			if (type === 'add') {
				if (this.$amount.equals(this.scanType, 1) && this.$amount.equals(this.scanNumber, 1))
					return this.$message({
						type: 'error',
						message: '正扫时最小值为1，不能再减了',
						duration: 2000,
						showClose: true
					})
				this.scanNumber--
			} else {
				if (this.$amount.equals(this.scanType, 2) && this.$amount.equals(this.scanNumber, -1))
					return this.$message({
						type: 'error',
						message: '负扫时最大值为-1，不能再加了',
						duration: 2000,
						showClose: true
					})
				this.scanNumber++
			}
		},
		
		// 货号添加
		sureAdd() {
			if (!this.barCode) return
			this.loading = true
			this.barCode = this.barCode.toUpperCase()
			queryGoodsByBarcode({inventoryRecordInfoId: this.areaData.stockOrdingId, barcode: this.barCode})
				.then((data) => {
					if (data) {
						this.totalUnit += Number(this.dialogNumber)
						let itemObj = {
							...data,
							barCode: this.barCode,
							scanNumber: this.dialogNumber,
							delKey: this.delKey++
						}
						this.barCodeList.unshift(itemObj)
						this.barCodeFilterList.unshift(itemObj)
						let rowIndex = this.dataList.findIndex((i) => i.goodsNo === data.goodsNo)
						if (rowIndex > -1) {
							this.dataList[rowIndex].sizeInfoList.forEach((item) => {
								if (item.barCode === this.barCode) {
									item.scanNumber = Number(item.scanNumber) + Number(this.dialogNumber)
								}
							})
						} else {
							this.dataList.unshift({
								...data,
								sizeInfoList: data.sizeInfoList.map((item) => ({
									...item,
									scanNumber: item.barCode === this.barCode ? this.dialogNumber : 0
								}))
							})
						}
						this.barCode = ''
						this.dialogVisibleTwo = false
						this.scanTip.play()
					}
					this.loading = false
				})
				.catch((err) => {
					this.scanWaring.play()
					this.loading = false
					this.$message({type: 'error', message: err, duration: 2000, showClose: true})
				})
		},
		//扫码添加
		pushBarCode() {
			this.scanCode = this.scanCode.toString().trim()
			if (!this.scanCode) return
			this.isDisable = true
			this.scanCode = this.scanCode.toUpperCase()
			queryGoodsByBarcode({inventoryRecordInfoId: this.areaData.stockOrdingId, barcode: this.scanCode})
				.then((data) => {
					if (data) {
						let scanTotalNumber =
							(this.barCodeList.reduce((total, item) => {
								return total + (item.barCode === this.scanCode ? item.scanNumber : 0)
							}, 0) || 0) + this.scanNumber
						if (scanTotalNumber < 0) {
							this.scanCode = ''
							this.isDisable = false
							this.$nextTick(() => {
								this.$refs.tagInput.focus()
							})
							this.$message({
								type: 'error',
								message: '盘点数量不能为负数',
								duration: 2000,
								showClose: true
							})
							this.scanWaring.play()
							return
						}
						this.totalUnit += Number(this.scanNumber)
						let itemObj = {
							...data,
							barCode: this.scanCode,
							scanNumber: this.scanNumber,
							delKey: this.delKey++
						}
						this.barCodeList.unshift(itemObj)
						this.barCodeFilterList.unshift(itemObj)
						
						let rowIndex = this.dataList.findIndex((i) => i.goodsNo === data.goodsNo)
						if (rowIndex > -1) {
							this.dataList[rowIndex].sizeInfoList.forEach((item) => {
								if (item.barCode === this.scanCode) {
									item.scanNumber = Number(item.scanNumber) + Number(this.scanNumber)
								}
							})
						} else {
							this.dataList.unshift({
								...data,
								sizeInfoList: data.sizeInfoList.map((item) => ({
									...item,
									scanNumber: item.barCode === this.scanCode ? this.scanNumber : 0
								}))
							})
						}
						this.dataList = this.dataList.filter(goods => {
							goods.isZero = goods.sizeInfoList.some(size => {
								return Number(size.scanNumber) > 0
							})
							return goods.isZero
						})
						this.scanCode = ''
						this.scanTip.play()
					}
					this.isDisable = false
					this.$nextTick(() => {
						this.$refs.tagInput.focus()
					})
				})
				.catch((err) => {
					this.isDisable = false
					this.$nextTick(() => {
						this.$refs.tagInput.focus()
					})
					this.scanCode = ''
					this.scanWaring.play()
					this.$message({type: 'error', message: err, duration: 2000, showClose: true})
				})
		},
		//货号弹窗
		openGoodsNoDialog() {
			if (this.notInput) {
				return this.$message({
					type: 'error',
					message: '请双击最左边的“锁”解锁',
					duration: 2000,
					showClose: true
				})
			}
			this.dialogVisible = true
			this.title = '选择添加'
			this.dialogType = 4
			this.condition = ''
			this.queryGoods()
		},
		queryGoods(val) {
			this.loading = true
			queryGoodsInfo({
				page: val || 1,
				pageSize: '10',
				condition: this.condition
			})
				.then((data) => {
					this.loading = false
					this.goodsList = data.items
					this.totalItem = Number(data.totalItem) || 0
				})
				.catch((err) => {
					this.loading = false
					this.$message({type: 'error', message: err, duration: 2000, showClose: true})
				})
		},
		queryCurgoods(goodsNo) {
			queryCurgoodsInfo({
				goodsNo: goodsNo
			})
				.then((data) => {
					this.goodsDetail = data
					if (data && data.colorInfoList && data.colorInfoList.length) {
						this.colorSize = data.colorInfoList[0]
						this.curColorDesc = data.colorInfoList[0].colorDesc
						this.curSizeDesc = data.colorInfoList[0].sizeInfoList[0].size
						this.barCode = data.colorInfoList[0].sizeInfoList[0].barCode
					}
					this.dialogVisibleTwo = true
					this.dialogNumber = 1
				})
				.catch((err) => {
					this.$message({type: 'error', message: err, duration: 2000, showClose: true})
				})
		},
		// 清空
		clearAll() {
			if (this.notInput) {
				return this.$message({
					type: 'error',
					message: '请双击最左边的“锁”解锁',
					duration: 2000,
					showClose: true
				})
			}
			if (this.barCodeList.length && this.dataList.length) {
				this.$confirm('是否清空记录？')
					.then(() => {
						this.barCodeList = []
						this.barCodeFilterList = []
						this.dataList = []
						this.totalUnit = 0
						this.$message({type: 'success', message: '操作成功！', duration: 2000, showClose: true})
					})
					.catch(() => {
					})
			}
		},
		searchCodeFn() {
			if (this.searchCode) {
				let list = this.searchCode.split(' ')
				this.barCodeFilterList = this.barCodeList.filter((i) => list.includes(i.barCode))
			} else {
				this.barCodeFilterList = _.cloneDeep(this.barCodeList)
			}
		},
		searchList() {
			this.filterText = this.goodsNoList
		},
		changNumber() {
			let reg = /^[1-9]\d*$/;
			if (!reg.test(this.dialogNumber)) {
				this.dialogNumber = 1
				this.$message({type: 'error', message: '请填写大于0的整数', duration: 2000, showClose: true})
			}
		},
		rowDel(row) {
			this.$confirm('删除当前记录右侧数量也会相应变化,是否删除？')
				.then(() => {
					let scanTotalNumber =
						(this.barCodeList.reduce((total, item) => {
							return total + (item.barCode === row.barCode ? item.scanNumber : 0)
						}, 0) || 0) - row.scanNumber
					if (scanTotalNumber < 0) {
						this.$message({
							type: 'error',
							message: '盘点数量不能为负数',
							duration: 2000,
							showClose: true
						})
						return
					}
					this.totalUnit -= row.scanNumber
					this.barCodeList = this.barCodeList.filter((i) => i.delKey !== row.delKey)
					this.barCodeFilterList = this.barCodeList.filter((i) => i.delKey !== row.delKey)
					this.dataList.forEach((item, index) => {
						if (item.goodsNo === row.goodsNo) {
							item.sizeInfoList.forEach((item) => {
								if (item.barCode === row.barCode) {
									item.scanNumber -= row.scanNumber
								}
							})
						}
						let isZero = item.sizeInfoList.every(size => {
							return !size.scanNumber
						})
						if (isZero) {
							this.dataList.splice(index, 1)
						}
					})
				})
				.catch(() => {
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	height: 100vh;
	overflow: hidden;
	font-family: 'font-Regular';
	
	.cont-tab-top {
		background: #fff;
		margin-bottom: 10px;
		margin: 10px 20px;
		border-radius: 2px;
		padding: 0 10px;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		.lock-area {
			width: 30px;
			height: 30px;
			margin-right: 20px;
			cursor: pointer;
			
			img {
				width: 30px;
				height: 30px;
			}
		}
		
		.scan-type {
			display: flex;
			align-items: center;
			margin-right: 20px;
			
			img {
				width: 80px;
				height: 30px;
				margin: 0 10px;
			}
			
			.cur-scan {
				color: #067cf2;
			}
		}
		
		.change-num {
			display: flex;
			align-items: center;
			margin-right: 20px;
			
			.circle {
				height: 30px;
				width: 30px;
				line-height: 30px;
				text-align: center;
				border-radius: 50%;
				background: #f7f7f9;
				
				.circle-icon {
					font-weight: bold;
					color: #ccc;
				}
			}
			
			.circle-num {
				margin: 0 10px;
				width: 30px;
				text-align: center;
			}
		}
		
		.btn {
			border: 1px solid #067cf2;
			color: #067cf2;
			border-radius: 4px;
			padding: 6px 15px;
			font-size: 14px;
			margin-right: 20px;
			cursor: pointer;
		}
		
		.cont-tab-top-l {
			display: flex;
			align-items: center;
		}
		
		.cont-tab-top-r {
			display: flex;
			align-items: center;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;
			
			.submit-content {
				background: #343134;
				height: 40px;
				line-height: 40px;
				padding: 0 10px;
			}
			
			.submit-btn {
				background: #067cf2;
				height: 40px;
				line-height: 40px;
				cursor: pointer;
				padding: 0 10px;
			}
		}
	}
	
	.table-data {
		height: calc(100vh - 140px);
		padding: 0 20px;
		
		.col-top {
			height: 52px;
			padding: 10px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			.col-title {
				font-weight: 900;
				color: #077cf2;
			}
		}
		
		.col-top-r {
			justify-content: flex-start;
			
			.col-title {
				margin-right: 40px;
			}
		}
		
		.search-box {
			display: flex;
			align-items: center;
			
			.search-label {
				margin-right: 10px;
			}
			
			.search-input {
				width: 210px;
				margin-right: 10px;
			}
			
			.search-input-goodsNo {
				width: 260px;
				margin-right: 10px;
			}
		}
	}
}

.default-style {
	width: 96px;
	line-height: 30px;
	background: #f7f7f9;
	text-align: center;
	border-radius: 4px;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	box-sizing: border-box;
	border: 1px solid #f7f7f9;
}

.select-style {
	color: #067cf2;
	border: 1px solid #067cf2;
	background: #f7f8fd;
}
</style>

<style>
.goods-search .el-input__inner {
	width: 460px;
	height: 36px;
	line-height: 36px;
	border-radius: 4px 0 0 4px;
}

.el-popconfirm__action {
	margin-top: 10px;
}
</style>
<style lang="scss">
.scan-card {
	display: flex;
	align-items: center;
	width: 300px;
	height: 32px;
	overflow: hidden;
	margin-right: 20px;
	border: 1px solid #dcdfe6;
	border-radius: 4px;
	
	.scan-title {
		width: 50px;
		height: 30px;
		line-height: 30px;
		font-weight: 900;
		border-right: 1px solid #dcdfe6;
		text-align: center;
	}
	
	.scan-input {
		flex: 1;
		border: none;
		box-sizing: border-box;
		height: 30px;
		line-height: 30px;
		background-color: transparent;
		outline: 0;
		padding: 0 10px;
		
		&::-webkit-input-placeholder {
			color: #ccc4d6;
		}
		
		&:focus {
			caret-color: #c56209 !important;
			
			&::-webkit-input-placeholder {
				color: #c56209 !important;
			}
		}
	}
	
	.scan-btn {
		width: 60px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		background: #067cf2;
		color: #fff;
	}
}

.bar-code-table {
	.cell {
		padding: 0;
	}
	
	.el-table__row .el-table__cell {
		padding: 0 10px;
		height: 26px !important;
	}
	
	.bar-code-table-header-cell {
		background: #fafafa !important;
	}
	
	.row-btn {
		color: #409eff;
		cursor: pointer;
	}
}

.select {
	background: #EFF7FF !important;
}
</style>
