//新增配置
export const columns = [
    {
        prop: 'index',
        type: 'index',
        label: '序号'
    },
    {
        prop: 'goodsNo',
        label: '货号'
    },
    {
        prop: 'color',
        label: '颜色'
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '0px' //复选框宽度
    },
    {
        prop: 'total',
        label: '盘点数量',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    },
    {
        prop: 'tagPrice',
        label: '吊牌价'
    },
    {
        prop: 'typeCategory',
        label: '款式分类'
    },
    {
        prop: 'styleCategory',
        label: '风格分类'
    },
    {
        prop: 'timeCategory',
        label: '时间分类'
    }
]

export const sizeInfoLabel = [
    {
        prop: 'scanNumber', //复选框key//valueInfoList当前行对应key
        label: '', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    }
]
